import { Card, CardContent, Divider, IconButton, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CustomImage from "../Image/CustomImage";

type ShortStoryProps = {
  title: string;
  date: string;
  url: string;
  image: string;
  text: ReactNode;
  altImage: string;
  variant: "left" | "right";
}

const ShortStoryCard: FC<ShortStoryProps> = ({ title, date, url, image, altImage, text, variant }) => {


  return (
    <Card elevation={0} className={"shortStoryCard"}>
      <CardContent className={`shortStoryContent ${variant === 'right' ? "reverse" : ""}`}>
        <div className="shortStoryCardText">
          <div className="shortStoryTitleContainer">
            <Typography variant="h5">
              {title}
              <IconButton color={'primary'} href={url} style={{ verticalAlign: 'middle' }} aria-label="settings">
                <OpenInNewIcon />
              </IconButton>
            </Typography>
          </div>
          <Typography className={"storyDate"} variant="body1">
            {date}
          </Typography>
          <br />
          <Divider className={"storyCardContentDivider"} />
          <br />
          <div className="storyCardText">
              {text}
          </div>
        </div>
        <div className="shortStoryImage">
          <CustomImage src={image} altImage={altImage}/>
          {/* <img
            className="shortStoryImage"
            alt={altImage}
            src={image}
          /> */}
        </div>
      </CardContent>
    </Card >);


}

export default ShortStoryCard