import React from 'react'
import Img, { GatsbyImage, MainImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from 'gatsby'

function renderImage(file:any, alt:string) {
  console.log(file);
  return <GatsbyImage imgStyle={{ borderRadius: '8px' }} image={file.node.childImageSharp.gatsbyImageData} alt={alt} />
}

const CustomImage = function(props: { src: string, altImage: string; }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find((image:any)  => image.node.relativePath === props.src), props.altImage
        )
      }
    />
  )
}

export default CustomImage