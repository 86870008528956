import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography, useScrollTrigger } from "@mui/material"
import React from "react"
import { Link as GatsbyLink } from "gatsby";
import ShortStoryCard from "../components/Card/ShortStoryCard";
import MenuIcon from '@mui/icons-material/Menu';
import { StaticImage } from "gatsby-plugin-image";


const IndexPage = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar elevation={trigger ? undefined : 0} position="sticky" className={`appBar ${!trigger ? "appBarSmall" : null}`}>
        <Toolbar className={"toolbar"}>
          <div className="container navBar">
            <div>
              <GatsbyLink className="homeLink" to='/'>
                <Typography color={"white"} variant="h5">MICHAEL PANTER</Typography>
              </GatsbyLink>
            </div>
            <div className="navbar-right">
              <IconButton
                className="menuButton"
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
                onClick={handleClick}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <GatsbyLink className="link" to='#contact'>
                    Contact
                  </GatsbyLink>
                </MenuItem>
              </Menu>

            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className="headSection section">
        <div className="container intro">
          <div className="introText">
            <Typography variant="h3">Michael Panter</Typography>
            <br />
            <Typography variant="subtitle1">
              <strong>
                About me
              </strong>
            </Typography>
            <Typography variant="body1">
            Michael Panter is an award-winning author and journalist from Kettering Town, England, currently residing in Stockholm, Sweden. His writing journey began humbly when, aged seven, he penned the sequel to Star Wars: The Phantom Menace. Sadly, George Lucas went with Attack of the Clones instead. 
            <br/><br/>
            In 2018, he decided to knuckle down and pursue his dream of becoming a full-time author of fantasy. That same year, his novella Deathsworn won a Watty, the highest honour available on Wattpad. Two years later, his novelette Red Hand made it to the final of the internationally-acclaimed Writers of the Future contest. In 2021, he was announced as a Writers of the Future winner with Lilt of a Lark, due to be published in their best-selling anthology in June of 2022. 
            <br/><br/>
            Michael is currently hard at work on a fantasy trilogy that he hopes will hit bookshelves before his hair goes grey.
            </Typography>
          </div>
          <div className="profilePictureWrapper">
            <div className="profilePictureBorder">
              <StaticImage
                src="../images/profile.jpg"
                className="profilePicture"
                alt="Profile"
                placeholder="blurred"
                layout="constrained"
                imgStyle={{ borderRadius: '8px' }}
              />
              </div>
          </div>
        </div>
      </div >
      <div className="section">
        <div className="container shortStories">
          <Typography className={"previousStories"} color="CaptionText" variant="subtitle1"><strong>Previous short stories</strong></Typography>
          <div className="shortStoryContainer">
            <ShortStoryCard
              title="Lilt of a Lark"
              date="June 28, 2022"
              url="https://www.amazon.com/Ron-Hubbard-Presents-Writers-Future/dp/161986763X?fbclid=IwAR1cHTwkeSlgrp6hyHguSZABJPMgBSBzyhzNYFLau195qAx3oUE2kkD0F-0"
              variant="right"
              image="writers-of-the-future.jpg"
              altImage="Writers of the future"
              text={
                <Typography>
                  25 Award-winning Authors and Illustrators<br />
                  Brilliant new worlds<br />
                  Captivating new ideas<br />
                  Powerful new stories of action, adventure, and fantasy<br />
                </Typography>
              }
            />
            <ShortStoryCard
              title="Red Hand"
              date="October 14, 2020"
              url="https://www.wattpad.com/story/243890608-red-hand?fbclid=IwAR0nKJ4dRYXLAWT96HG4M8N-dlsgWFPz5WuD50jFDoZrQx5izy2Lpq5CU78"
              image="red-hand.jpg"
              variant="left"
              altImage="Red Hand"
              text={
                <Typography variant="body1">
                  Shadows and secrets. A castle in turmoil. A lord's heir taken, feared dead.
                  <br />
                  <br />
                  Reeve is no stranger to mystery and menace. From town to village he wanders, extorting the horrors of a broken realm, a meek soul seeking purpose in a world forged for the strong.
                  When the son of a powerful lord vanishes from his bed, Reeve has no choice but to accept a summons. His talents have started to attract attention, though that's the last thing a man with a secret wants.
                  <br />
                  <br />
                  He's charged with finding answers in a place where the dawn heralds deception and patience is running perilously thin. Someone knows something, and no one can hide from Reeve for long.
                  Dangerous people are his bread and butter. Sadly, not all monsters are created equal.
                </Typography>
              }
            />
            <ShortStoryCard
              title="Deathsworn, vol 2"
              date="June 12, 2019"
              url="https://www.wattpad.com/story/188166075-deathsworn-vol-ii?fbclid=IwAR1Rl9T2O6MDNJ2T3JFCZmTQTSUPhhfGNsIAEjawY-mOa0OCpnxhBL_YrSM"
              image="death-sworn-vol2.jpeg"
              variant="right"
              altImage="Deathsworn, vol 2"
              text={
                <Typography variant="body1">
                  <strong>Sequel to Watty's winner 2018</strong>
                  <br />
                  Best read after Vol. I
                  <br />
                  <br />
                  <i>"Killing is the draught that must needs be drunk. Guilt is but the coin we use to pay for it."</i>
                  <br />
                  <br />
                  Moons have passed and wounds have healed, but the memories of Hammar are as lucid for Bard as the future is bleak. Once more the Gallowmen must venture forth armed with their wits, their weapons and their will, united in the knowledge that only through death can they purchase life.
                  <br />
                  <br />
                  Their relationship with the Burned Priests straddles the edge of a wicked knife, and though their list may grow shorter, patience is a commodity that has run its course. Now the Gallowmen must travel as far east as one can go, to a corner of the map thats notoriety lies in it being perilously unforgiving to those who know it not.
                  <br />
                  <br />
                  Here, death stalks the jungles, creatures of the dark hold dominion, and life is a luxury pledged only to the strong. At the edge of the world, Bard and his rogues must navigate more than mere men to get what they need. Their life is their list ... if they can survive long enough to see it through.
                </Typography>
              }
            />
            <ShortStoryCard
              title="Deathsworn, vol 1"
              date="May 20, 2019"
              url="https://www.wattpad.com/story/129566165-deathsworn-wattys-2018-winner?fbclid=IwAR3X34_WyRArmm9gr_KXeVCYDGy_lDmRRj1A9g8l70uGDi6Fw-6Ohou5kAk"
              image="death-sworn-vol1.jpeg"
              variant="left"
              altImage="Deathsworn, vol 1"
              text={
                <Typography variant="body1">
                  <strong>WATTYS 2018 WINNER</strong>
                  <br />
                  <br />
                  Not one person in the history of the world ever said being Deathsworn was an easy thing. Fun, sometimes, and completely your fault, but never easy. You have one rule that you obey, unless, of course, you want to die.
                  <br />
                  <br />
                  Your life is your list, your list is your life.
                  <br />
                  <br />
                  For the Gallowmen, their journey as Deathsworn has seen them travel the broken realm of Oblivia. From Green Country to the Island Kingdoms, from Sand Country to the Scavania. Death follows where they go, their list gets shorter, and the dream of life becomes half-real.
                  <br />
                  <br />
                  It would be fine if things were that simple. But curious things are happening in the west and the Burned Priests are charged with confronting the Empire's greatest threats. Where they point, the Deathsworn must go.                  <br />
                  <br />
                  They'll do their job, ask no questions, and get their freedom. Or they'll die trying.
                </Typography>
              }
            />
          </div>
        </div>
      </div>
      <div className="section footerSection">
        <div className="container">
          <Typography variant="subtitle1" id={"contact"}><strong>Contact</strong></Typography>
          <a className={'link'} href="mailto:michael.panter@gmail.com">
            <Typography variant="body1">michael.panter@gmail.com</Typography>
          </a>
        </div>
      </div>
    </>
  )
}

export default IndexPage
